@import "mixins";
@import "fonts";
@import "vendor";
@import "vars";
@import "settings";
// @import "~bootstrap/scss/bootstrap";

body.modal-open {
    height: 100vh;
    overflow-y: hidden;
}
.test-btn {
    cursor: pointer;
    margin: 30px;
    // max-width: 209px;
    font-family: 'Montserrat' !important;
    padding: 18px 64px;
    box-sizing: border-box;
    background: #F2AB3C;
    border-radius: 16px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;    
}
.charity-form-section {
    display: none;
    overflow-y: auto;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;
    height: max-content;

    width: 100vw;
    min-height: 100vh;
    height: 100vh;

    &._active {
        background-color: rgba($color: #000000, $alpha: .5);       
    }


    * {
        font-family: 'Montserrat' !important;
        font-weight: 400;
        font-style: normal;
        color: $text-main-color;
        text-decoration: none;
    
        min-height: 0;
        min-width: 0;
    }
    // .charity-form__overlay {
    //     width: 100vw;
    //     height: 100%;
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     background-color: rgba($color: #000000, $alpha: .5);
    //     z-index: 10;
    // }
    .charity-form__outer {
        position: relative;
        // transform: translateY(25%);
        // top: 500px;
        left: 0;
        right: 0;
        // bottom: 0;
        // top: 50%;
        // left: 50%;
        // transform: translateX(-50%);
        margin: auto;
        height: max-content;

        z-index: 20;
        background-color: #ADE1FA;
        border-radius: 24px;   
        padding: 34px 36px;
        box-sizing: border-box;
        max-width: 1200px;    
        width: 100%;

        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;

        &._300 {
            background-image: url("../img/form-bg-300.png");
        }
        &._500 {
            background-image: url("../img/form-bg-500.png");
        }
        &._700 {
            background-image: url("../img/form-bg-700.png");
        }
        &._1000 {
            background-image: url("../img/form-bg-1000.png");
        }
        &._5000 {
            background-image: url("../img/form-bg-5000.png");
        }
    }
    .charity-form__container {
        
    }
    .charity-form__top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 22px;
    }
    .charity-form__header {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        /* or 36px */
        color: #187DAD;
        max-width: 594px;
    }
    .charity-form__close {
        width: 18px;
        height: 18px;
        padding: 0;
        background: transparent;
        border: none;
        position: relative;
        cursor: pointer;

        &::before, &::after {
            content: '';
            width: 3px;
            height: 18px;
            background: #187DAD;
            display: block;
            position: absolute;
            top: 0;
            right: 50%;
            transform: translateX(-50%);
            border-radius: 3px;
        }
        &::before {
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
    }
    .charity-form__wrapper {
        display: flex;
        justify-content: start;
    }
    .charity-form {
        background: #FDFDFD;
        border-radius: 24px;

        &._stage1 {
            max-width: 452px; 
            padding: 22px 26px;
            box-sizing: border-box;
            margin-right: 18px;

            transition: filter .3s;

            &._disabled {
                filter: grayscale(1);
                // pointer-events: none;
            }
        }
        &._stage2 {
            display: none;
            max-width: 654px; 
            padding: 37px 26px;
            box-sizing: border-box;

            & .charity-form__block .charity-form__input {
                margin-bottom: 8px;
            }
        }
    }
    .charity-form__text {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        
        /* identical to box height, or 24px */
        color: #000000;
        display: block;
        margin-bottom: 16px;
    }
    .charity-form__block {
        &:not(:last-child) {
            margin-bottom: 32px;
        }
        &:last-child {
            margin-bottom: 60px;
        }
    }
    .charity-form {
        
    }
    .charity-form__label {
        position: relative;
        span {
            display: inline-block;
            border: 1px solid #DADADA;
            border-radius: 16px;
            background-color: transparent;
            padding: 10px 20px;
            box-sizing: border-box;
    
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            /* identical to box height, or 24px */
            color: #4A4A4A;
            margin-bottom: 8px;
        }
    }
    .charity-form__check {
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        top: 0;

        &:checked + span {
            background: #F2AB3C;
            border-color: #F2AB3C;  
            color: #fff; 
        }
    }
    .charity-form__input {
        padding: 10px 20px;
        box-sizing: border-box;

        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 16px;  
        
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        
        /* identical to box height, or 24px */
        
        color: #4A4A4A;
        transition: border-color .3s;  
        
        &:focus-visible {
            border-color: #F2AB3C;
            outline: #F2AB3C;
        }

        &._error {
            border-color: red;
        }
    }
    .charity-form__textarea {
        width: 100%;
        padding: 10px 20px;
        box-sizing: border-box;

        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 16px;
        
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        
        /* identical to box height, or 24px */
        
        color: #4A4A4A;
        max-height: 128px;
        resize: none;

        &:focus-visible {
            border-color: #F2AB3C;
            outline: #F2AB3C;
        }
    }
    .charity-form__submit-block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }
    .form-btn {
        max-width: 209px;
        padding: 18px 64px;
        box-sizing: border-box;
        background: #F2AB3C;
        border-radius: 16px;  
        border: none;

        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */
        color: #FFFFFF; 
    }
    .charity-form__submit {

    }
    .charity-form__policy {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        
        /* or 18px */
        
        color: #000000;
    }
    .charity-form__average {
        display: block;
        font-style: normal;
        font-weight: 600;
        font-size: 44px;
        line-height: 100%;
        /* identical to box height, or 44px */
        text-align: center; 
        margin-bottom: 8px;      
    }
    .charity-form__desc {
        display: block;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */
        text-align: center;
        color: #737373; 
        margin-bottom: 40px;      
    }
    .charity-form__privacy-label {
        display: block;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        /* identical to box height, or 18px */
        color: #000000;
        margin-bottom: 42px;
        
        input {
            margin-right: 9px;
        }
    }
    .charity-form__back {
        display: none;
        position: absolute;
        top: 30px;
        left: 38px;
        z-index: 200;
        background-color: transparent;
        border: none;
        width: 22px;
        height: 18px;
        padding: 0;
    
        img {
            width: 100%;
        }
    }
}


@media screen and (max-width: 800px) {
    .charity-form-section .charity-form__outer {
        min-height: 911px;
    }
    .charity-form-section .charity-form__back {
        display: block;
    }
    .charity-form-section .charity-form._stage2 {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding-top: 113px;
        padding-left: 81px;
        padding-right: 81px;
    }
    .charity-form-section .charity-form._stage2 .charity-form__block .charity-form__input {
        width: 100%;
    }
    .charity-form-section .charity-form._stage2 .charity-form__text {
        text-align: center;
    }
    .charity-form-section .charity-form._stage2 .charity-form__block .charity-form__label span {
        width: 100%;
        text-align: center;
    }
    .charity-form-section .charity-form._stage2 .charity-form__submit-block {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .charity-form-section .charity-form__close {
        z-index: 200;
    }
    
}

@media screen and (max-width: 450px) {
    .charity-form-section .charity-form__outer {
        border-radius: 0;
        min-height: 100vh;
        height: max-content;
        padding: 50px 16px;
    }
    .charity-form-section .charity-form__header {
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 150%;
        /* or 33px */
        color: #187DAD;
    }
    .charity-form-section .charity-form__top {
        margin-bottom: 40px;
    }
    .charity-form-section .charity-form__close {
        position: absolute;
        top: 23px;
        right: 25px;
    }
    .charity-form-section .charity-form__block:not(:last-child) {
        margin-bottom: 32px;
    }
    .charity-form-section .charity-form._stage1 {
        margin-right: 0;
        padding: 22px 20px;
    }
    .charity-form-section .charity-form__textarea {
        margin-top: 24px;
    }
    .charity-form-section .charity-form__submit-block {
        display: flex;
        justify-content: start;
        align-items: flex-start;
        flex-direction: column-reverse;
    }
    .charity-form-section .charity-form._stage2 {
        padding: 113px 16px;
    }
    .charity-form-section .charity-form__average {
        text-align: left;
        font-weight: 600;
        font-size: 44px;
        line-height: 100%;        
    }
    .charity-form-section .charity-form__desc {
        text-align: left;
    }
    .charity-form-section .charity-form__back {
        left: 20px;
        top: 20px;
    }    
    .charity-form-section .charity-form__privacy-label {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;      
        
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .charity-form-section .charity-form__privacy-label input {
        position: relative;
        top: 5px;
        min-width: 24px;
        height: 24px;
    }
}