//Тут происходят вызовы шрифтов
// @font-face {
//     font-family: 'Inter';
//     src: url('../fonts/Inter-SemiBold.ttf');
//     // font-weight: 600;
//     font-style: normal;
// }

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}